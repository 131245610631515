import React from 'react';
import anime from 'animejs';
import './Contact.css'
import close from './assets/close.svg';
import axios from 'axios';


class Contact extends React.Component {
  state = {
    name: '',
    email: '',
    message: '',
    disabled: false,
    text: 'SUBMIT'
  }
  componentDidUpdate() {
    // // check for visible prop and animate accordingly
    // let box1 = this.refs.box1
    // let box2 = this.refs.box2

    // anime({
    //   targets: box1,
    //   width: '100vw'
    // })
  }

  componentDidMount() {

    // check for visible prop and animate accordingly
    window.controller.openContact = this.openWindow;
    window.controller.closeContact = this.closeWindow;
    // this.openWindow()

  }

  closeWindow = async () => {
    let box1 = this.refs.box1;
    let title = this.refs.title;
    let container = document.getElementById('form');


    await Promise.all([
      anime({
        targets: title,
        opacity: 0,
        duration: 500,

        easing: 'easeInOutQuad'
      }).finished,
      anime({
        targets: container,
        opacity: 0,
        duration: 500,
        easing: 'easeInOutQuad'
      }).finished,
      anime({
        targets: box1,
        opacity: 0,
        duration: 500,
        delay: 500,
        easing: 'easeInOutQuad',
      }).finished
    ])

    document.body.style.overflow = 'unset'
    box1.style.display = 'none';
    title.style.opacity = 0;
    container.style.opacity = 0;
    container.style.display = 'none';
  }

  openWindow = async () => {
    let box1 = this.refs.box1;
    let title = this.refs.title;
    let container = document.getElementById('form');

    box1.style.display = 'block';
    document.body.style.overflow = 'hidden'
    container.style.display = 'flex';

    await Promise.all([
      anime({
        targets: box1,
        opacity: 1,
        duration: 500,
        //delay: 3000,
        easing: 'easeInOutQuad',
      }).finished,
      anime({
        targets: title,
        opacity: 1,
        duration: 500,
        delay: 500,
        easing: 'easeInOutQuad'
      }).finished,
      anime({
        targets: container,
        opacity: 1,
        duration: 500,
        delay: 1000,
        easing: 'easeInOutQuad'
      }).finished
    ])


  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  }

  onSumbit = async (e) => {
    e.preventDefault();
    this.setState({
      disabled: true,
      text: 'SUBMITTING...'
    })
    try {
      // const { email, name, message } = this.state;
      let baseUrl = document.location.href;
      await axios.post(baseUrl + 'contact', {
        name: this.state.name,
        email: this.state.email,
        message: this.state.message
      })

      alert('Your message has been sent')

    } catch (err) {
      alert('ERROR: ' + err.message);
    }

    this.setState({
      disabled: false,
      text: 'SUMBIT'
    })
  }

  render() {
    return (
      <div className="work">
        <div
          ref="box1"
          style={{
            position: 'fixed',
            left: 0,
            top: 0,
            opacity: 0,
            width: '100%',
            height: '100%',
            display: 'none',
            backgroundColor: 'black'
          }}>
          <div style={{ opacity: 0 }} ref="title" className="nav">

            <div className="nav-inner">
              <h1 >CONTACT</h1>
              <img draggable={false} onClick={this.closeWindow} src={close} />
            </div>

          </div>


          {
            //<div id="mixtape" className="mixtape">
          }

          <form
            noValidate={false}
            onSubmit={this.onSumbit}
            id="form" style={{
              display: 'none',
              opacity: 0
            }}>
            <label>
              Full Name:
              <input required type="text" name="name" value={this.state.name} onChange={this.handleChange('name')} />
            </label>
            <label>
              Email:
              <input required type="email" name="email" value={this.state.email} onChange={this.handleChange('email')} />
            </label>
            <label>
              Message:
              <textarea required type="text" name="message" value={this.state.message} onChange={this.handleChange('message')} />
            </label>
            <input type="submit" disabled={this.state.disabled} value={this.state.text}></input>
            <p>You can also email <a href="mailto:imran@imranbey.com">imran@imranbey.com</a></p>
          </form>



        </div>


      </div>
    );
  }

}



export default Contact;
