import React from 'react';
import logo from './logo.svg';
import Frame from './Frame';
import Greeting from './Greeting';
import Work from './Work';
import Contact from './Contact';
import './App.css';

class App extends React.Component {

  state = {
    workVisible: false
  }

  componentWillMount() {
    window.controller = {};
  }

  render() {
    return (
      <div className="App">
        <Frame />
        <Greeting />
        <Work visible={this.state.workVisible} />
        <Contact />
      </div>
    );
  }

}

export default App;
