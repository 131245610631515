import React from 'react';
import { Curtains } from 'curtainsjs';
import './Frame.css';
import Brain from './assets/brain.png';
var isMobile = require('is-mobile');


class Circles extends React.Component {

  componentDidMount() {
    if (isMobile()) return;

    let el = document.getElementById('border');

    let num = 45 + getScrollbarWidth();

    el.style.right = '' + num + 'px';


    let logo = document.getElementById('logo-container');

    num = 65 + getScrollbarWidth();

    logo.style.right = '' + num + 'px';
  }

  render() {
    return (
      <div id="frame" className="frame-container">
        <div id="border" className="border" />
        <div className="frame">

          <h1 onClick={() => window.controller.openWork()} className="work-title">WORK</h1>
          <h1 onClick={() => window.controller.openContact()} className="contact-title">CONTACT</h1>

          <iframe
            src={process.env.PUBLIC_URL + '/water.html'}
          // srcDoc={html}
          ></iframe>


        </div>
        <h3 style={{
          position: 'absolute',
          bottom: 0,

          fontWeight: 100,
          opacity: 0,
          textAlign: 'center',
          fontSize: 14,
          fontFamily: 'sans-serif',
          color: 'white'
        }}>Scroll Down</h3>
        <div id="logo-container" className="logo-container">
          <h2>imranbey</h2>
          <h3>studios</h3>
        </div>
        <div className="scroll-container">
          <div className='icon-scroll' />

        </div>

      </div>
    );
  }

}

function getScrollbarWidth() {
  var outer = document.createElement("div"); outer.style.visibility = "hidden"; outer.style.width = "100px"; outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

  document.body.appendChild(outer);

  var widthNoScroll = outer.offsetWidth;
  // force scrollbars
  outer.style.overflow = "scroll";

  // add innerdiv
  var inner = document.createElement("div");
  inner.style.width = "100%";
  outer.appendChild(inner);

  var widthWithScroll = inner.offsetWidth;

  // remove divs
  outer.parentNode.removeChild(outer);

  return widthNoScroll - widthWithScroll;
}

export default Circles;


