import React from 'react';
import logo from './logo.svg';
import Frame from './Frame';
import './Greeting.css';
var isMobile = require('is-mobile');


class App extends React.Component {

  scrollTop = 0;
  opacity = 1;

  componentDidMount() {
    window.onscroll = (e) => {
      // console.log(window.pageYOffset)
      let opacity;
      let currentOffset = window.pageYOffset;
      let maxVal = 760;

      let slope = isMobile() ? -1.1 : -.8;

      opacity = slope * (currentOffset / maxVal) + 1;



      this.scrollTop = window.pageYOffset;
      this.opacity = opacity;
    }

    let el = document.getElementById("frame");


    let updateOpacity = (timestamp) => {
      el.style.opacity = this.opacity;
      requestAnimationFrame(updateOpacity) // call requestAnimationFrame again to animate next frame
    }
    requestAnimationFrame(updateOpacity) // call requestAnimationFrame and pass into it animation function
  }

  render() {
    let text = `Hello, my name is Imran Shitta-Bey,
    a passionate software developer based in the greater Houston
    area. If you'd like to take your business into
    the modern era, please `
    return (

      <div
        id="greeting-container"
        className="greeting-container">
        <h1

        >WELCOME</h1>

        <p >{text}
          <span className="underline" onClick={() => window.controller.openContact()} >contact me.</span>
          {' Or you can check out '}
          <span
            onClick={() => window.controller.openWork()}
            className="underline">
            my work.</span>

        </p>
      </div>


    );
  }

}

export default App;
