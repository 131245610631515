import React from 'react';
import anime from 'animejs';
import './Work.css'
import close from './assets/close.svg';
import { FaReact, FaNodeJs } from 'react-icons/fa';
import { DiMongodb } from 'react-icons/di';
import ReactTooltip from 'react-tooltip';

class Work extends React.Component {

  componentDidMount() {
    // check for visible prop and animate accordingly
    window.controller.openWork = this.openWindow;
    window.controller.closeWork = this.closeWindow;
  }

  closeWindow = async () => {
    let box1 = this.refs.box1;
    let title = this.refs.title;
    let container = [document.getElementById('container'), document.getElementById('work-text')];


    await Promise.all([
      anime({
        targets: title,
        opacity: 0,
        duration: 500,

        easing: 'easeInOutQuad'
      }).finished,
      anime({
        targets: container,
        opacity: 0,
        duration: 500,
        easing: 'easeInOutQuad'
      }).finished,
      anime({
        targets: box1,
        opacity: 0,
        duration: 500,
        delay: 500,
        easing: 'easeInOutQuad',
      }).finished
    ])


    document.body.style.overflow = 'unset'
    box1.style.display = 'none';
    title.style.opacity = 0;
    // container.style.opacity = 0;
    // container.style.display = 'none';
    container.forEach(doc => {
      doc.style.opacity = 0
      doc.style.display = 'none';
    })

  }

  openWindow = async () => {
    let box1 = this.refs.box1;
    let title = this.refs.title;
    // let container = document.getElementById('container');
    let container = [document.getElementById('container'), document.getElementById('work-text')];

    box1.style.display = 'block';
    document.body.style.overflow = 'hidden'
    // container.style.display = 'block'
    container.forEach(doc => {
      doc.style.display = 'block'
    })

    await Promise.all([
      anime({
        targets: box1,
        opacity: 1,
        duration: 500,
        //delay: 3000,
        easing: 'easeInOutQuad',
      }).finished,
      anime({
        targets: title,
        opacity: 1,
        duration: 500,
        delay: 500,
        easing: 'easeInOutQuad'
      }).finished,
      anime({
        targets: container,
        opacity: 1,
        duration: 500,
        delay: 1000,
        easing: 'easeInOutQuad'
      }).finished
    ])

    animateCard()

  }


  render() {
    return (
      <div className="work">
        <div
          ref="box1"
          style={{
            position: 'fixed',
            left: 0,
            top: 0,
            opacity: 0,
            width: '100%',
            height: '100%',
            display: 'none',
            overflowY: 'auto',
            backgroundColor: 'black'
          }}>
          <div style={{ opacity: 0 }} ref="title" className="nav">

            <div className="nav-inner">
              <h1 >WORK</h1>
              <img draggable={false} onClick={this.closeWindow} src={close} />
            </div>

          </div>


          {
            //<div id="mixtape" className="mixtape">
          }


          <div
            className="work-image-wrapper"
          >
            <a href="https://bugg.imranbey.com" target="_blank">
              <div
                style={{ opacity: 0, display: 'none' }}
                id="container">
                <div id="inner">
                  <div className="demo-img">

                  </div>
                </div>
              </div >
            </a>
          </div>


          <div
            id="work-text"
            style={{ opacity: 0, display: 'none' }}
            className="work-text">
            <div
              className="work-text-header"
            >
              <div>
                <h2
                  className="work-header-title"
                  style={{
                    fontFamily: '"Roboto", sans-serif'
                  }}>
                  BUGG
                </h2>
                <p
                  className="desktop-description"
                  style={{
                    fontFamily: 'Roboto Mono',
                    opacity: .85
                  }}>
                  A simple bug tracker. <a
                    style={{ color: 'white' }}
                    href="https://github.com/Pickleboyonline/bug-tracker"> View on Gitub</a>

                </p>
              </div>

              <div className="work-text-icons">
                <DiMongodb
                  data-tip="MongoDB" opacity={.6} size={35} />
                <FaNodeJs data-tip="NodeJs" opacity={.6} size={35} />
                <FaReact data-tip="ReactJs" opacity={.6} size={35} />

              </div>
              <ReactTooltip className='roboto-font-tooltip' />
            </div>


            <p
              className="mobile-description"
              style={{
                fontFamily: 'Roboto Mono',
                opacity: .85
              }}>
              A simple bug tracker. <a
                style={{ color: 'white' }}
                href="https://github.com/Pickleboyonline/bug-tracker"> View on Gitub</a>

            </p>
            <br className="mobile-description" />

            <ul style={{
              fontFamily: 'Roboto Mono',
              opacity: .85,

            }}>
              <li>
                Custom full-stack application
              </li>
              <li>
                Authentication
              </li>
              <li>
                Create and manage bugs, projects, and users
              </li>
              <li>
                Roles & permission enforcement
              </li>
              <li>
                Realtime notifications and messages
              </li>
            </ul>
          </div>


        </div>
      </div>
    );
  }
}


function animateCard() {
  // ===========================================================
  // See tutorial at : 
  // https://css-tricks.com/animate-a-container-on-mouse-over-using-perspective-and-transform/
  // ===========================================================

  (function () {
    // Init
    var container = document.getElementById("container"),
      inner = document.getElementById("inner");

    // Mouse
    var mouse = {
      _x: 0,
      _y: 0,
      x: 0,
      y: 0,
      updatePosition: function (event) {
        var e = event || window.event;
        this.x = e.clientX - this._x;
        this.y = (e.clientY - this._y) * -1;
      },
      setOrigin: function (e) {
        this._x = e.offsetLeft + Math.floor(e.offsetWidth / 2);
        this._y = e.offsetTop + Math.floor(e.offsetHeight / 2);
      },
      show: function () {
        return "(" + this.x + ", " + this.y + ")";
      }
    };

    // Track the mouse position relative to the center of the container.
    mouse.setOrigin(container);

    //----------------------------------------------------

    var counter = 0;
    var refreshRate = 10;
    var isTimeToUpdate = function () {
      return counter++ % refreshRate === 0;
    };

    //----------------------------------------------------

    var onMouseEnterHandler = function (event) {
      update(event);
    };

    var onMouseLeaveHandler = function () {
      inner.style = "";
    };

    var onMouseMoveHandler = function (event) {
      if (isTimeToUpdate()) {
        update(event);
      }
    };

    //----------------------------------------------------

    var update = function (event) {
      mouse.updatePosition(event);
      updateTransformStyle(
        (mouse.y / inner.offsetHeight / 2).toFixed(2),
        (mouse.x / inner.offsetWidth / 2).toFixed(2)
      );
    };

    var updateTransformStyle = function (x, y) {
      var style = "rotateX(" + x + "deg) rotateY(" + y + "deg)";
      inner.style.transform = style;
      inner.style.webkitTransform = style;
      inner.style.mozTranform = style;
      inner.style.msTransform = style;
      inner.style.oTransform = style;
    };

    //--------------------------------------------------------

    container.onmousemove = onMouseMoveHandler;
    container.onmouseleave = onMouseLeaveHandler;
    container.onmouseenter = onMouseEnterHandler;
  })();

}

export default Work;
